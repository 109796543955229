
import { defineComponent } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import { string } from "yup";
import config from "@/config";
import { web2Lead } from "./web2Lead";

const eventLocationStates = [
  {
    value: "ACT",
    label: "Australian Capital Territory",
  },
  {
    value: "NSW",
    label: "New South Wales",
  },
  {
    value: "NT",
    label: "Northern Territory",
  },
  {
    value: "QLD",
    label: "Queensland",
  },
  {
    value: "SA",
    label: "South Australia",
  },
  {
    value: "TAS",
    label: "Tasmania",
  },
  {
    value: "VIC",
    label: "Victoria",
  },
  {
    value: "WA",
    label: "Western Australia",
  },
];

export default defineComponent({
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const formData = {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      howManyTickets: "",
      eventLocation: "",
      eventLocationState: "",
      eventDescription: "",
    };

    const validation = {
      firstName: string().required(),
      lastName: string().required(),
      phone: string().required(),
      email: string().required().email(),
      eventDescription: string().required(),
    };
    return { formData, validation, eventLocationStates };
  },
  methods: {
    onSubmit(): void {
      // do thing
      const payload = {
        oid: config.SALESFORCE_WEB_2_LEAD_OID,
        retURL: config.SALESFORCE_WEB_2_LEAD_RET_URL,
        Brand__c: config.SALESFORCE_WEB_2_LEAD_BRAND,
        lead_source: "Sales Web Lead",
        // debug: "1",
        // debugEmail: "larenelg@oztix.com.au",
        first_name: this.formData.firstName,
        last_name: this.formData.lastName,
        phone: this.formData.phone,
        email: this.formData.email,
        How_many_tickets_will_be_for_sale__c: this.formData.howManyTickets,
        Where_is_the_event__c:
          this.formData.eventLocation + " " + this.formData.eventLocationState,
        description: this.formData.eventDescription,
      };

      if (process.env.NODE_ENV === "production") {
        web2Lead(payload);
      } else {
        // debug
        console.log("web2Lead payload", payload);
      }
    },
  },
});
