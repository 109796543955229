export type PromotionContent = {
    textDescription: string;
    imgSrc: string;
    url: string;
    id: string;
}

export const CURRENT_PROMOTIONS =  [
    {
        textDescription: "Sign up for Good Things 2024 featuring KORN SUM41 VIOLENTFEMMES and others!",
        imgSrc: require('@/assets/promoted/goodthings24_oztix_600x300_lineup_signupnow.gif'),
        url: "https://www.goodthingsfestival.com.au/?utm_source=oztix&utm_medium=homepage&utm_content=adset",
        id: "good-things-2024-signup-001",
    },
    {
        textDescription: "Sign up for Good Things 2024 featuring KORN SUM41 VIOLENTFEMMES and others!",
        imgSrc: require('@/assets/promoted/goodthings24_oztix_600x300_lineup_signupnow.gif'),
        url: "https://www.goodthingsfestival.com.au/?utm_source=oztix&utm_medium=homepage&utm_content=adset",
        id: "good-things-2024-signup-002",
    }
]

export const BACKUP_PROMOTIONS = [
    {
        textDescription: "$100 off your first case of wine at Naked Wines",
        imgSrc: require('@/assets/promoted/naked-wines-001-rs.png'),
        url: "https://www.nakedwines.com.au/ztix100",
        id: "naked-wines-001",
    },
    {
        textDescription: "Escape to K'Gari (Fraser Island) with Sunrover",
        imgSrc: require('@/assets/promoted/sunrover-tours-001-rs.png'),
        url: "https://www.sunrover.com.au/?utm_source=oztix&utm_medium=homepage&utm_content=displayad",
        id: "sunrover-camping-001",
    }
]
